import React from 'react'
import { useTranslation } from 'react-i18next'
import './Tickets.css'

const Tickets = () => {
	const { t } = useTranslation()

	const cardList = [
		{
			img: './img/malasia.jpg',
			title: 'Malayziya',
			price: 289,
		},
		{
			img: './img/marokash.jpg',
			title: 'Marokash',
			price: 610,
		},
		{
			img: './img/maldiv.jpg',
			title: 'Maldiv orollari',
			price: 770,
		},
		{
			img: './img/bali.jpg',
			title: 'Bali',
			price: 449,
		},
	]
	return (
		<div className='container'>
			<h1 className='tickets-title'>{t('Top values for you')}</h1>
			<div className='row'>
				{cardList.map(item => (
					<div className='col-xl-3 col-md-6 col-12 my-2' key={item.title}>
						<div className='tickets-card'>
							<div className='tickets-card-img'>
								<img
									className='w-100'
									height={'202.85px'}
									src={item.img}
									alt='card-img'
								/>
							</div>

							<div className='tickets-card-desc'>
								<div className='tickets-card-title text-nowrap'>
									{item.title}
								</div>
								<div className='tickets-card-price'>
									<span className='fw-bolder text-nowrap'>$ {item.price}</span>
								</div>
							</div>
							<button className='tickets-card-btn'>
								<a href='#contacts' aria-current='page'>
									<span className='fw-bold me-2'>{t('More')}</span>
									<img src='./img/tickets-card-arrow-icon.svg' alt='icon' />
								</a>
							</button>
						</div>
					</div>
				))}
			</div>
		</div>
	)
}

export default Tickets
