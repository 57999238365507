import React from 'react'
import { useTranslation } from 'react-i18next'
import './Service.css'

const Service = () => {
	const { t } = useTranslation()

	const cardList = [
		{
			title: t('Airport pickup'),
			desc: t('Some text which identifies this service clearly1'),
			img: './img/service-card-icon1.svg',
		},
		{
			title: t('Easy booking'),
			desc: t('Some text which identifies this service clearly2'),
			img: './img/service-card-icon2.svg',
		},
		{
			title: t('Best tour guide'),
			desc: t('Some text which identifies this service clearly3'),
			img: './img/service-card-icon3.svg',
		},
		{
			title: t('Lots of promos'),
			desc: t('Some text which identifies this service clearly4'),
			img: './img/service-card-icon4.svg',
		},
	]
	return (
		<div className='container service' id='about-us'>
			<h1 className='service-title'>{t('Top values for you')}</h1>
			<p className='service-title-desc'>
				{t('Try variety benefits when using our services')}
			</p>

			<div className='row'>
				{cardList.map(item => (
					<div className='col-lg-3 col-sm-6 col-12' key={item.title}>
						<div className='service-card'>
							<div className='service-card-img-box'>
								<img src={item.img} alt={item.title} />
							</div>
							<h3 className='service-card-title'>{item.title}</h3>
							<p className='service-card-desc'>{item.desc}</p>
						</div>
					</div>
				))}
			</div>
		</div>
	)
}

export default Service
