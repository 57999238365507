import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import LanguageSelector from '../language-selector/LanguageSelector'
import './Navbar.css'

const navbarList = [
	{
		name: 'Our Tours',
		hash: '#our-tours',
		active: 'active',
	},
	{
		name: 'About Us',
		hash: '#about-us',
		active: '',
	},
	{
		name: 'Booking',
		hash: '#booking',
		active: '',
	},
	{
		name: 'Contacts',
		hash: '#contacts',
		active: '',
	},
]

const Navbar = () => {
	const { t } = useTranslation()
	const [navbar, setNavbar] = useState(navbarList)

	const activeItem = hash => {
		var newNavbarList = []

		navbarList.map(item => {
			if (item.hash === hash) {
				newNavbarList.push({ ...item, active: 'active' })
			} else {
				newNavbarList.push({ ...item, active: '' })
			}
		})

		setNavbar(newNavbarList)
	}

	return (
		<nav className='navbar-content'>
			<div className='navbar navbar-expand-lg navbar-fixed-top'>
				<div className='container'>
					<a className='navbar-brand' href='#'>
						<img
							src='./img/logo-eleven.png'
							alt='Logo'
						/>
					</a>
					<button
						className='navbar-toggler'
						type='button'
						data-bs-toggle='collapse'
						data-bs-target='#navbarText'
						aria-controls='navbarText'
						aria-expanded='false'
						aria-label='Toggle navigation'
					>
						<span className='navbar-toggler-icon'></span>
					</button>
					<div className='collapse navbar-collapse' id='navbarText'>
						<ul className='navbar-nav mx-auto mb-2 mb-lg-0'>
							{navbar?.map(item => (
								<li className='nav-item' key={item.hash}>
									<a
										onClick={() => activeItem(item?.hash)}
										className={`nav-link ${item?.active}`}
										aria-current='page'
										href={item?.hash}
									>
										{t(item?.name)}
									</a>
								</li>
							))}
						</ul>
						<div className='language-btn'>
							<LanguageSelector />
						</div>
					</div>
				</div>
			</div>
		</nav>
	)
}

export default Navbar
